import React, { Component, Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import asyncComponent from "./components/layout/AsyncComponent";
import DealView from "./components/forms/Deals/DealView/DealView";
import AcceptTerms from "./components/forms/Deals/AcceptTerms";
import ErrorPage from "./components/pages/ErrorPage";

class App extends Component {
  render() {
    const { location } = this.props;

    // const { isLoading } = this.props.app;

    return (
      <Fragment>
        <div>
          <Header />
          <div>
            <Switch>
              <Route
                path="/accept-terms/:emailId/:investorId"
                location={location}
                component={asyncComponent(() => AcceptTerms)}
              />
              <Route
                path="/placement/:dealId/:uuid"
                location={location}
                component={asyncComponent(() => DealView)}
              />
              <Route component={asyncComponent(() => ErrorPage)} />
            </Switch>
          </div>
          {/* <Footer /> */}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(App);
