import React, { Component } from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import DealsViewForm from "./DealsViewForm/DealsViewForm";
import { connect } from "react-redux";
import EntityActions from "../../../../redux/actions/entityActions";
import dealActions from "../../../../redux/actions/dealActions";
import { withRouter } from "react-router-dom";
import BidActions from "../../../../redux/actions/bids.Actions";
import { BaseUrl } from "../../../../services/settings";

const queryString = require("query-string");

class DealView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEntityOptions: [],
      open: false,
      modalOpen: false,
      file: "",
      termsAccepted: false,
    };
  }

  modalOpen = () => {
    this.setState({ modalOpen: true });
  };

  modalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount = () => {
    let { fetchAllEntities, match, getDealStatus } = this.props;
    getDealStatus({
      dealId: match.params.dealId,
      investorUuid: match.params.uuid,
    });
    fetchAllEntities(match.params.uuid);
  };

  cancelBidHanler = (id) => {
    let { cancelBid, history } = this.props;

    this.setState({ open: false });
    cancelBid({ bidId: id }, history);
  };

  editBidHandler = (id) => {
    const { location } = this.props;

    const parsed = queryString.parse(location.search);
    if (parsed) {
      if (parseInt(parsed.bid) === 1) {
        this.props.history.push(`/user/edit-bid/${id}?bid=1`);
      } else {
        this.props.history.push(`/user/edit-bid/${id}`);
      }
    } else {
      this.props.history.push(`/user/edit-bid/${id}`);
    }
  };

  onSubmitBid = async (values) => {
    let {
      createBidSubmit,
      currentDeal,
      bidStatus,
      history,
      match,
      existingEntity,
      getDealStatus,
    } = this.props;
    if (!bidStatus.myBid) {
      if (bidStatus.type === "180") {
        Modal.confirm({
          title: "Confirm",
          width: 700,
          okButtonProps: {
            type: "primary",
            size: "large",
          },
          cancelButtonProps: {
            size: "large",
          },
          icon: <ExclamationCircleOutlined />,
          content: (
            <p className="pconfirm">
              By submitting a bid, you are making a binding and irrevocable
              offer to acquire the securities, on the terms set out in the
              information available here, in the{" "}
              {currentDeal && currentDeal.term_sheets ? (
                <a
                  target="_blank"
                  href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
                >
                  Term sheet
                </a>
              ) : (
                "Term sheet"
              )}{" "}
              and in the{" "}
              {
                <a
                  target="_blank"
                  href="https://180.instaging.net/investor/terms&conditions"
                >
                  Investor Terms
                </a>
              }
              . You confirm you make each of the representations and warranties
              specified in the Investor Terms.Your bid does not guarantee that
              you will receive an allocation. If your bid is successful, you
              will be required to pay to acquire the securities in accordance
              with your bid and the Investor Terms, subject to any scale back.
            </p>
          ),
          okText: "Confirm",
          cancelText: "Cancel",
          async onOk() {
            await createBidSubmit(
              {
                ...values,
                deal_id: bidStatus.id,
                deal_uuid: match.params.uuid,
                investor_id: existingEntity.id,
              },
              history
            );

            setTimeout(() => {
              getDealStatus({
                dealId: match.params.dealId,
                investorUuid: match.params.uuid,
              });
            }, 200);
          },
          onCancel() {},
        });
      }
    }
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name;
    }

    return name;
  };

  render() {
    let {
      currentDeal,
      currentUser,
      bidStatus,
      location,
      existingEntity,
    } = this.props;
    const parsed = queryString.parse(location.search);

    const existingEntityOptions =
      existingEntity &&
      existingEntity.investor_entities &&
      existingEntity.investor_entities.map((entity) => {
        if (entity.entity_company !== null)
          return {
            value: entity.id,
            text: entity.entity_company.name,
            value: entity.id,
          };
        else if (entity.entity_trust !== null)
          return {
            value: entity.id,
            text: entity.entity_trust.name,
            value: entity.id,
          };
        else if (entity.entity_individual !== null)
          return {
            value: entity.id,
            text: this.renderIndividualName(entity),
            value: entity.id,
          };
      });

    return (
      <main className="page-deal-add-doc">
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-5" style={{ margin: "80px auto" }}>
                <div className="md-deal-form">
                  <div className="form-normal">
                    {bidStatus &&
                    (bidStatus.status === "Active" ||
                      bidStatus.status === "Suspended") &&
                    !bidStatus.myBid ? (
                      <>
                        <DealsViewForm
                          title="Submit Bid"
                          queryString={parsed.bid}
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={existingEntityOptions || []}
                          capRaisePrice={bidStatus && bidStatus.capital_raise}
                          currentDeal={currentDeal}
                          bidStatus={bidStatus}
                        />
                      </>
                    ) : bidStatus &&
                      (bidStatus.status === "Active" ||
                        bidStatus.status === "Suspended") &&
                      bidStatus.myBid ? (
                      <div class="form-create-account">
                        <div style={{ textAlign: "center" }}>
                          <h3>Thank you for your bid.</h3>
                        </div>
                      </div>
                    ) : bidStatus &&
                      (bidStatus.status === "Closed" ||
                        bidStatus.status === "Finished") ? (
                      <div class="form-create-account">
                        <div style={{ textAlign: "center" }}>
                          <h3>This deal is closed. </h3>
                        </div>
                      </div>
                    ) : !bidStatus ? (
                      <div class="form-create-account">
                        <div style={{ textAlign: "center" }}>
                          <h3>This deal is closed. </h3>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    existingEntity: state.entities.entities,
    currentDeal: state.deals.singleDeal,
    bidStatus: state.deals.bidStatus,
  };
};

const { fetchEntities } = EntityActions;
let { singlePublicDeal, getSingleDeal } = dealActions;
let { createBid, deleteBid, createIndication } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  getSingleDealData: (payload) => singlePublicDeal(payload, dispatch),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
  createBidSubmit: (payload, history) => createBid(payload, dispatch, history),
  createIndicationSubmit: (payload, history) =>
    createIndication(payload, dispatch, history),
  cancelBid: (payload, history) => deleteBid(payload, dispatch, history),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DealView));
