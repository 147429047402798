import React, { Component } from "react";
import { Form, reduxForm, Field } from "redux-form";
import { FormField, MaskedPriceFormat } from "../../../../layout/FormField";
import { required, numericality } from "redux-form-validators";
import { connect } from "react-redux";
import EntityActions from "../../../../../redux/actions/entityActions";
import { shares } from "../../../../../utils/customValidators";
import { MIN_BID_AMOUNT } from "../../../../../utils/constants";
import { withRouter } from "react-router-dom";

class DealsViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warning: false,
    };
  }

  componentDidMount = () => {
    let { fetchEntitiesData, currentUser } = this.props;

    if (currentUser) {
      fetchEntitiesData();
    }
  };

  onShareCountChangeHander = (e) => {
    this.props.change(
      "investment_amount",
      parseFloat(e.target.value * this.props.capRaisePrice).toFixed(2)
    );
  };

  onInvestmentChangeHandler = (e) => {
    if (typeof e !== "object") {
      return this.props.currentDeal.type !== "market"
        ? this.props.change(
            "share_count",
            (parseFloat(e) / parseFloat(this.props.capRaisePrice)).toFixed(0)
          )
        : null;
    }
  };

  onBlur = (e) => {
    const { form } = this.props;
    if (
      form &&
      form.DealsViewForm &&
      form.DealsViewForm.values &&
      form.DealsViewForm.values.share_count
    ) {
      this.props.change(
        "investment_amount",
        parseFloat(
          form.DealsViewForm.values.share_count * this.props.capRaisePrice
        ).toFixed(2)
      );
    }
  };

  render() {
    let { handleSubmit, bidStatus } = this.props;
    let min_bid =
      bidStatus.min_bid &&
      bidStatus.min_bid !== undefined &&
      bidStatus.min_bid !== null
        ? bidStatus.min_bid
        : MIN_BID_AMOUNT;
    return (
      <div>
        {this.props.title && <h3>{this.props.title}</h3>}

        <div className="deal-form-content">
          <Form onSubmit={handleSubmit}>
            <div className="all-fields">
              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <div className="field-input">
                        <label className="title">Select Entity </label>
                        <div className="field-input">
                          <Field
                            className="form-control"
                            name="entity_id"
                            component={FormField}
                            type="select"
                            validate={[required()]}
                            options={
                              this.props.selectEntityOptions &&
                              this.props.selectEntityOptions
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <div className="field-input">
                        <label className="title">Share Count </label>
                        <div className="field-input">
                          <Field
                            className="form-control"
                            name="share_count"
                            component={FormField}
                            type="number"
                            onBlurHandler={this.onShareCountChangeHander}
                            validate={[
                              required(),
                              numericality({
                                ">": 0,
                              }),
                            ]}
                            normalize={shares}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="field">
                    <div className="field-input">
                      <label className="title">Investment Amount</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="investment_amount"
                          component={MaskedPriceFormat}
                          onBlurHandler={this.onBlur}
                          onChange={this.onInvestmentChangeHandler}
                          type="number"
                          validate={[
                            required(),
                            numericality({
                              ">=": min_bid,
                              msg: {
                                ">=": `Min bid amount is $${min_bid}.`,
                              },
                            }),
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="button-group">
                <div className="col-sm-12">
                  <button
                    style={{ marginTop: "10px" }}
                    type="submit"
                    className="btn btn-primary btn-medium"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

const { fetchEntities } = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  fetchEntitiesData: (payload, history) => fetchEntities(dispatch),
});

export default reduxForm({ form: "DealsViewForm", enableReinitialize: true })(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(DealsViewForm))
);
