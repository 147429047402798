import React, { Component } from "react";
import { Icon, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import dealActions from "../../../../redux/actions/dealActions";
import { withRouter } from "react-router-dom";
import { BaseUrl } from "../../../../services/settings";

const queryString = require("query-string");

class AcceptTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAccepted: false,
    };
  }

  componentDidMount = () => {
    let { fetchPriorityEmail, match, dispatch } = this.props;
    fetchPriorityEmail({
      emailId: match.params.emailId,
      investorId: match.params.investorId,
    });
  };

  acceptTerms = () => {
    let { acceptTerms, match } = this.props;

    this.setState({
      termsAccepted: true,
    });
    acceptTerms({
      emailId: match.params.emailId,
      investorId: match.params.investorId,
    });
  };

  render() {
    let { priorityEmail } = this.props;

    return (
      <main className="page-deal-add-doc">
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-5" style={{ margin: "80px auto" }}>
                {priorityEmail && priorityEmail.deal && (
                  <div className="md-deal-form">
                    {!this.state.termsAccepted ? (
                      <div className="form-normal">
                        <>
                          {priorityEmail.deal && !priorityEmail.is_accepted ? (
                            <>
                              {" "}
                              <div className="row">
                                <div className="col-md-12">
                                  {priorityEmail.deal.status !== "Closed" ? (
                                    <>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            priorityEmail.deal &&
                                            priorityEmail.deal.terms &&
                                            priorityEmail.deal.terms,
                                        }}
                                      />
                                      <div className="row p-4">
                                        <div className="col-md-12">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-medium"
                                            onClick={() => this.acceptTerms()}
                                          >
                                            Accept Terms
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="form-create-account">
                                      <div className="text-center">
                                        <h3>Sorry, this Placement is now closed.</h3>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="form-normal">
                                <div className="form-create-account">
                                  <div className="text-center">
                                    <h3>
                                      You have already accepted the terms and
                                      conditions.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    ) : (
                      <div className="form-normal">
                        <div className="form-create-account">
                          <div className="text-center">
                            <h3>
                              Thank you we will email you the deal information shortly.
                            </h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    priorityEmail: state.deals.priorityEmail,
  };
};

let { fetchPriorityEmail, acceptTerms } = dealActions;

const mapDispatchToProps = (dispatch) => ({
  fetchPriorityEmail: (payload) => fetchPriorityEmail(payload, dispatch),
  acceptTerms: (payload) => acceptTerms(payload),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AcceptTerms));
