import React from "react";
import Logo from "../../assets/images/180M-logo.png";
import { LogoUnlocked, UserIcon } from "../common/Graphics";
import { Link } from "react-router-dom";
import { Icon } from "antd";

const BrandLogo = ({ path }) => (
  <div className="logo">
    <Link to={path}>
      <img src={LogoUnlocked} alt="Home" />
    </Link>
  </div>
);

const Search = () => {
  return (
    <form>
      <div className="field-input">
        <input type="text" className="form-control" />
        <button className="btn-search"></button>
      </div>
    </form>
  );
};

const UserNotification = () => (
  <div className="notification">
    <div className="toggle-notification">
      <span></span>
    </div>
  </div>
);

const LiveDealsButton = ({ handleClick }) => {
  return (
    <Link
      to="/user/deals"
      onClick={handleClick}
      className="btn btn-outline-primary"
      style={{ height: "auto" }}
    >
      LIVE DEALS
    </Link>
  );
};

const LoginButton = () => {
  return (
    <Link to="/auth/login" className="btn btn-outline-primary">
      Login
    </Link>
  );
};
const UpgradeAccountButton = () => {
  return (
    <Link
      to="/user/profile"
      className="btn btn-outline-primary float-left mr-4"
    >
      Upgrade account
    </Link>
  );
};

const MenuToggle = (props) => {
  return (
    <div className="toggle-menu" onClick={() => props.handleClick()}>
      <Icon
        type={!props.showMobileMenu ? "menu" : "close"}
        style={{ fontSize: "20px", verticalAlign: "1.875em !important" }}
      />
    </div>
  );
};

const Avator = ({ url = null, name, showUserMenu }) => {
  return (
    <div className="avatar" onClick={() => showUserMenu()}>
      <img
        style={{ height: "100%", width: "100%" }}
        src={url ? url : UserIcon}
        alt={name}
      />
    </div>
  );
};

export {
  BrandLogo,
  Search,
  LoginButton,
  MenuToggle,
  UserNotification,
  Avator,
  UpgradeAccountButton,
  LiveDealsButton,
};
