import { combineReducers } from "redux";
import entityReducer from "./entityReducer";
import bidsReducer from "./bidsReducer";
import dealReducer from "./dealReducer";
import { reducer as formReducer } from "redux-form";
import loadingReducer from "./Loading";

export default combineReducers({
  form: formReducer,
  entities: entityReducer,
  deals: dealReducer,
  loading: loadingReducer,
  bids: bidsReducer,
});
