import React, { Component } from "react";
import { BrandLogo } from "../common";
import { withRouter } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileMenu: false,
    };
  }
  toggleMobileMenu = () => {
    this.setState((prevState) => {
      return {
        showMobileMenu: !prevState.showMobileMenu,
      };
    });
  };

  render() {
    const { location } = this.props;

    return (
      <div>
        <header className="page-header clearfix">
          <div className="md-header-desktop clearfix d-none d-lg-block">
            <div className="container">
              <BrandLogo path={location.pathname} />
              <div className="main-nav-desktop"></div>
            </div>
          </div>

          <div className="md-header-mobile d-block d-lg-none">
            <div className="top-navtigation">
              <div className="container">
                <div className="ctn-inner">
                  <BrandLogo path={location.pathname} />{" "}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default withRouter(Header);
