import React, { Component, Fragment } from "react";
import App from "./../App";
import { Route, Switch, withRouter } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";

class AppLayout extends Component {
  render() {
    console.log("Props", this.props);
    const { location } = this.props;

    return (
      <Fragment>
        <Switch>
          <Route
            path="/accept-terms/:emailId/:investorId"
            location={location}
            component={App}
          />

          <Route
            path="/placement/:dealId/:uuid"
            location={location}
            component={App}
          />
          {/* <Route path="/" render={() => <Redirect to="/:uuid" />} /> */}
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(AppLayout);
