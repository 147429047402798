const BaseUrl =
  process.env.REACT_APP_API_HOST || "https://admin.180markets.com.au/";
const developmentEnvironment = process.env.NODE_ENV;

const ApiUrl = `${BaseUrl}frontend/v2/`;

const config = () => ({
  headers: {
    Authorization: "Bearer " + localStorage.getItem("auth_token"),
  },
});

const configMultipart = () => ({
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("auth_token"),
  },
});

export { BaseUrl, config, configMultipart, ApiUrl, developmentEnvironment };
