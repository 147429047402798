import EntityService from "../../services/entityService";
import { toast } from "../../components/common/Toast";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";

const actions = {
  FETCH_ALL_ENTITIES: "FETCH_ALL_ENTITIES",
  FETCH_ALL_ENTITIES_ERROR: "FETCH_ALL_ENTITIES_ERROR",
  FETCH_ALL_ENTITIES_SUCCESS: "FETCH_ALL_ENTITIES_SUCCESS",

  FETCH_SINGLE_ENTITY: "FETCH_SINGLE_ENTITY",
  FETCH_SINGLE_ENTITY_ERROR: "FETCH_SINGLE_ENTITY",
  FETCH_SINGLE_ENTITY_SUCCESS: "FETCH_SINGLE_ENTITY_SUCCESS",

  CREATE_ENTITY: "CREATE_ENTITY",
  CREATE_ENTITY_ERROR: "CREATE_ENTITY_ERROR",
  CREATE_ENTITY_SUCCESS: "CREATE_ENTITY_SUCCESS",

  DELETE_ENTITY: "DELETE_ENTITY",
  DELETE_ENTITY_ERROR: "DELETE_ENTITY_ERROR",
  DELETE_ENTITY_SUCCESS: "DELETE_ENTITY_SUCCESS",
  UNMOUNT_SINGLE_ENTITY_SUCCESS: "UNMOUNT_SINGLE_ENTITY_SUCCESS",
  BENEFICIAL_OWNERS: "BENEFICIAL_OWNERS",

  entitySuccess(payload) {
    return { type: actions.FETCH_ALL_ENTITIES_SUCCESS, payload };
  },

  fetchEntities: ({ dispatch, payload }) =>
    EntityService.getAll(payload)
      .then(({ data }) => {
        dispatch({
          type: actions.FETCH_ALL_ENTITIES_SUCCESS,
          payload: data,
        });
      })
      .catch(() => {}),

  fetchSingleEntity: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_SINGLE_ENTITY_SUCCESS,
      payload,
    });
  },

  // updateBeneficialOwners: (dispatch, payload) => {
  //   dispatch({
  //     type: actions.BENEFICIAL_OWNERS,
  //     payload,
  //   });
  // },

  unmountSingleEntity: (dispatch, payload) => {
    dispatch({
      type: actions.UNMOUNT_SINGLE_ENTITY_SUCCESS,
      payload,
    });
  },

  fetchSingleEntityData: async ({ payload, dispatch }) =>
    await EntityService.getSingle(payload)
      .then((res) => {
        actions.fetchSingleEntity(dispatch, res);
        // if (res.data.entity_trust.beneficial_owners) {
        //   actions.updateBeneficialOwners(
        //     dispatch,
        //     res.data.entity_trust.beneficial_owners
        //   );
        // }
      })
      .catch((error) => {
        console.log(error);
      }),

  createEntity: ({ payload, dispatch }) => {
    dispatch(startButtonLoading);

    EntityService.createEntity(payload)
      .then(() => {
        toast.success("Entity Created Successfully.");
        dispatch(stopButtonLoading);

        dispatch(actions.fetchEntities({ dispatch }));
      })
      .catch(() => {
        dispatch(stopButtonLoading);
      });
  },

  createCompanyEntity: ({ payload, dispatch, history }) => {
    dispatch(startButtonLoading);

    return EntityService.createCompanyEntity(payload)
      .then((res) => {
        dispatch(stopButtonLoading);

        if (res) {
          toast.success("Entity Created Successfully.");
          history.push("/user/profile");
        }
      })
      .catch(() => {
        dispatch(stopButtonLoading);
      });
  },

  createTrustEntity: ({ payload, dispatch, history }) => {
    dispatch(startButtonLoading);
    return EntityService.createTrustEntity(payload)
      .then(() => {
        dispatch(stopButtonLoading);

        toast.success("Entity Created Successfully.");
        history.push("/user/profile");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
      });
  },

  createIndividualEntity: ({ payload, dispatch, history }) => {
    dispatch(startButtonLoading);

    return EntityService.createIndividualEntity(payload)
      .then(() => {
        dispatch(stopButtonLoading);

        toast.success("Entity Created Successfully.");
        history.push("/user/profile");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
      });
  },

  updateCompanyEntity: ({ payload, dispatch, history }) => {
    dispatch(startButtonLoading);
    return EntityService.updateCompanyEntity(payload)
      .then(() => {
        toast.success("Entity Updated Successfully.");
        dispatch(stopButtonLoading);
        history.push("/user/profile");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        toast.error(error.message);
      });
  },

  updateTrustEntity: ({ payload, dispatch, history }) => {
    dispatch(startButtonLoading);
    return EntityService.updateTrustEntity(payload)
      .then(() => {
        dispatch(stopButtonLoading);

        toast.success("Entity Updated Successfully.");

        history.push("/user/profile");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        toast.error(error.message);
      });
  },

  updateIndividualEntity: ({ payload, dispatch, history }) => {
    dispatch(startButtonLoading);

    return EntityService.updateIndividualEntity(payload)
      .then((res) => {
        dispatch(stopButtonLoading);

        toast.success("Entity Updated Successfully.");

        history.push("/user/profile");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        toast.error(error.message);
      });
  },

  deleteEntity: ({ payload, dispatch }) =>
    EntityService.deleteEntity(payload)
      .then(() => {
        dispatch(() => actions.fetchEntities({ dispatch }));
        toast.success("Entity Deleted Successfully.");
      })
      .catch((error) => {
        toast.error(error.message);
      }),

  deleteIndividualEntity: (dispatch, index, fetchID) =>
    EntityService.deleteIndividualEntity(index)
      .then(() => {
        dispatch(() =>
          actions.fetchSingleEntityData({ payload: fetchID, dispatch })
        );
        toast.success("Entity Deleted Successfully.");
      })
      .catch((error) => {
        toast.error(error.message);
      }),
};
export default actions;
